import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Modal } from "@material-ui/core";

// @material-ui/icons
import Home from "@material-ui/icons/Home";
import House from "@material-ui/icons/House";
import KingBed from "@material-ui/icons/KingBed";
import Restaurant from "@material-ui/icons/Restaurant";
import SingleBed from "@material-ui/icons/SingleBed";
import Wc from "@material-ui/icons/Wc";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/style.js";

import out from "assets/img/house/out.jpg";
import out2 from "assets/img/house/out_2.jpg";
import out4 from "assets/img/house/out_4.jpg";
import kitchen from "assets/img/house/kitchen.jpg";
import suite from "assets/img/house/suite.jpg";
import room1 from "assets/img/house/room1.jpg";
import room2 from "assets/img/house/room2.jpg";
import bar from "assets/img/house/bar.jpg";
import lounge from "assets/img/house/lounge.jpg";
import stairs from "assets/img/house/stairs.jpg";
import bathroom2 from "assets/img/house/bathroom2.jpg";
import bathroom3 from "assets/img/house/bathroom3.jpg";
import living_room from "assets/img/house/living_room.jpg";

import NavPills from "components/NavPills/NavPills.js";

const useStyles = makeStyles(styles);

export default function HouseSection() {
  const classes = useStyles();

  const [visibleImg, setVisibleImg] = React.useState(null);

  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const onImgClick = (img) => setVisibleImg(img);

  return (
    <div className={classes.section} id="house">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Alojamento</h2>
          <h5 className={classes.description}>
            Trata-se de uma moradia totalmente renovada, composta por uma suite
            com casa de banho privativa dentro do quarto e outros dois quartos
            de casal, com uma casa de banho de apoio a cada um. Possui várias
            áreas comuns para lazer, nomeadamente: duas salas, uma delas com um
            bar onde pode optar por relaxar com uma bebida ao final do dia.
            Possui também uma cozinha ampla, totalmente equipada com
            eletrodomésticos e utensílios de cozinha para permitir preparar
            qualquer refeição.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Exterior",
                  tabIcon: House,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={out2}
                          className={navImageClasses}
                          onClick={() => onImgClick(out2)}
                        />
                        <img
                          alt="..."
                          src={out}
                          className={navImageClasses}
                          onClick={() => onImgClick(out)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={out4}
                          className={navImageClasses}
                          onClick={() => onImgClick(out4)}
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "Áreas comuns",
                  tabIcon: Home,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={living_room}
                          className={navImageClasses}
                          onClick={() => onImgClick(living_room)}
                        />
                        <img
                          alt="..."
                          src={bar}
                          className={navImageClasses}
                          onClick={() => onImgClick(bar)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={stairs}
                          className={navImageClasses}
                          onClick={() => onImgClick(stairs)}
                        />
                        <img
                          alt="..."
                          src={lounge}
                          className={navImageClasses}
                          onClick={() => onImgClick(lounge)}
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "Cozinha",
                  tabIcon: Restaurant,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={kitchen}
                          className={navImageClasses}
                          onClick={() => onImgClick(kitchen)}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={work4}
                          className={navImageClasses}
                        />
                        <img
                          alt="..."
                          src={work5}
                          className={navImageClasses}
                        />
                      </GridItem> */}
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "Suite",
                  tabIcon: KingBed,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={suite}
                          className={navImageClasses}
                          onClick={() => onImgClick(suite)}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={work2}
                          className={navImageClasses}
                        />
                        <img
                          alt="..."
                          src={work1}
                          className={navImageClasses}
                        />
                        <img
                          alt="..."
                          src={studio1}
                          className={navImageClasses}
                        />
                      </GridItem> */}
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "2 Quartos",
                  tabIcon: SingleBed,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={room1}
                          className={navImageClasses}
                          onClick={() => onImgClick(room1)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={room2}
                          className={navImageClasses}
                          onClick={() => onImgClick(room2)}
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
                {
                  tabButton: "2 WC",
                  tabIcon: Wc,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={bathroom2}
                          className={navImageClasses}
                          onClick={() => onImgClick(bathroom2)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <img
                          alt="..."
                          src={bathroom3}
                          className={navImageClasses}
                          onClick={() => onImgClick(bathroom3)}
                        />
                      </GridItem>
                    </GridContainer>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>

      <Modal
        open={!!visibleImg}
        style={{ display: "flex", cursor: "pointer" }}
        onClick={() => setVisibleImg(null)}
      >
        <img
          alt="..."
          src={visibleImg}
          style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
        />
      </Modal>
    </div>
  );
}
