import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import InsertDriveFile from "@material-ui/icons/InsertDriveFile";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/style.js";

import fedr from "assets/img/funds/fedr.png";
import norte2020 from "assets/img/funds/norte2020.png";
import portugal2020 from "assets/img/funds/portugal2020.png";

import pdf from "assets/docs/modelo_web_villa_juncal.pdf";

const useStyles = makeStyles(styles);

export default function FundsSection() {
  const classes = useStyles();

  const formLink =
    "https://docs.google.com/forms/d/e/1FAIpQLSezwlexXWXSsws-OpuJQGUKYUKIHcrpwvxIyiahXQaD1mzOWQ/viewform?vc=0&c=0&w=1&flr=0";

  return (
    <div id="contacts" className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={12}>
          {/* <h2 className={classes.title}>Reservas</h2> */}
          <h4 className={classes.description}>Cofinanciado por:</h4>
          <img alt="Norte2020" className={classes.fundsImg} src={norte2020} />
          <img
            alt="Portugal2020"
            className={classes.fundsImg}
            src={portugal2020}
          />
          <img
            alt="Fundo Europeu de Desenvolvimento Regional"
            className={classes.fundsImg}
            src={fedr}
          />
        </GridItem>

        <GridItem cs={12} sm={12} md={12}>
          <Button
            color="secondary"
            href={pdf}
            style={{ margin: 10 }}
            target="_blank"
          >
            <InsertDriveFile className={classes.icons} />
            Ficha do Projeto
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
