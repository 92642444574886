import React from "react";
import Carousel from "react-slick";
import { makeStyles } from "@material-ui/core/styles";

import LocationOn from "@material-ui/icons/LocationOn";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import mirandela from "assets/img/region/mirandela.jpg";
import braganca from "assets/img/region/braganca.jpg";
import azibo from "assets/img/region/azibo.jpeg";
import beach from "assets/img/region/praia-fluvial-vale-de-juncal.jpg";

import styles from "assets/jss/material-kit-react/views/landingPageSections/style.js";

const useStyles = makeStyles(styles);

export default function RegionSection() {
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className={classes.section} id="region">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Região</h2>
          <h5 className={classes.description}>
            Este alojamento situa-se no norte de Portugal, na aldeia de Vale de
            Juncal, a pouco mais de 5 km de Mirandela. Com a praia fluvial de
            Vale de Juncal a menos de 10 minutos a pé.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img
                    src={beach}
                    alt="Praia Fluvial de Vale de Juncal"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h3>
                      <LocationOn className="slick-icons" />
                      Praia Fluvial de Vale de Juncal
                    </h3>
                  </div>
                </div>
                <div>
                  <img
                    src={mirandela}
                    alt="Mirandela"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h3>
                      <LocationOn className="slick-icons" />
                      Mirandela
                    </h3>
                  </div>
                </div>
                <div>
                  <img src={azibo} alt="Azibo" className="slick-image" />
                  <div className="slick-caption">
                    <h3>
                      <LocationOn className="slick-icons" />
                      Praia Fluvial do Azibo
                    </h3>
                  </div>
                </div>
                <div>
                  <img src={braganca} alt="Bragança" className="slick-image" />
                  <div className="slick-caption">
                    <h3>
                      <LocationOn className="slick-icons" />
                      Bragança
                    </h3>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
