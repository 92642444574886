import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Explore from "@material-ui/icons/Explore";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/style.js";

const useStyles = makeStyles(styles);

export default function LocationSection() {
  const classes = useStyles();

  return (
    <div className={classes.section} id="location">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Localização</h2>

          <Button
            color="primary"
            href="https://www.google.com/maps/dir//41.5385278,-7.1855278/@41.538529,-7.1877077,815m/data=!3m1!1e3"
            target="_blank"
            style={{ marginBottom: 24 }}
          >
            <Explore className={classes.icons} /> como chegar
          </Button>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3550.2608682566915!2d-7.187707682522487!3d41.5385290025335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDHCsDMyJzE4LjciTiA3wrAxMScwNy45Ilc!5e1!3m2!1spt-PT!2spt!4v1624123674140!5m2!1spt-PT!2spt"
            style={{ width: "100%", height: 400, border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
