import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Bookmark from "@material-ui/icons/Bookmark";
import Facebook from "@material-ui/icons/Facebook";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/style.js";

const useStyles = makeStyles(styles);

export default function ContactsSection() {
  const classes = useStyles();

  const formLink =
    "https://docs.google.com/forms/d/e/1FAIpQLSezwlexXWXSsws-OpuJQGUKYUKIHcrpwvxIyiahXQaD1mzOWQ/viewform?vc=0&c=0&w=1&flr=0";

  return (
    <div id="contacts" className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Reservas</h2>
          <h4 className={classes.description}>
            Para reservas aceda à pagina do alojamento na Booking ou no nosso
            Facebook. Para mais informações entre em contacto pelo nosso
            Facebook.
          </h4>

          <Button
            color="info"
            href="https://www.booking.com/hotel/pt/villa-juncal.html"
            style={{ margin: 10 }}
            target="_blank"
          >
            <Bookmark className={classes.icons} /> Booking
          </Button>

          <Button
            color="info"
            href="https://www.facebook.com/Villa-Juncal-101180055450366"
            style={{ margin: 10 }}
            target="_blank"
          >
            <Facebook className={classes.icons} /> Facebook
          </Button>

          <Button
            color="info"
            href={formLink}
            style={{ margin: 10 }}
            target="_blank"
          >
            <Assignment className={classes.icons} />
            Check-in
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
