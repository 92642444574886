import { title } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const style = {
  section: {
    padding: "50px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  imgRounded: {
    ...imagesStyle.imgRounded,
    cursor: "pointer",
  },
  description: {
    color: "#999",
  },
  fundsImg: {
    maxHeight: "80px",
    maxWidth: "50vw",
    margin: "20px",
  },
};

export default style;
