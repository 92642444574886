import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import HouseSection from "./Sections/HouseSection.js";
import RegionSection from "./Sections/RegionSection.js";
import LocationSection from "./Sections/LocationSection.js";
import ContactsSection from "./Sections/ContactsSection.js";
import FundsSection from "./Sections/FundsSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Villa Juncal"
        rightLinks={<HeaderLinks />}
        leftLinks={<HeaderLeftLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />

      <Parallax filter image={require("assets/img/landing.jpeg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <h1 className={classes.title}>Villa Juncal</h1>
              <h4>
                Longe da cidade, perto da natureza. Casa de campo em Vale de
                Juncal, a cerca de 10 minutos de carro do centro de Mirandela.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <HouseSection />
          <RegionSection />
          <LocationSection />
          <ContactsSection />
          <FundsSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
